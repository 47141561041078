import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBoxes, FaArrowCircleRight, FaExclamationTriangle, FaChartLine } from 'react-icons/fa';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const StreamlineInventory = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const renderStrategyCTA = (title, list, color) => (
    <div>
      <p className="mb-4 text-gray-300">{title}</p>
      <ul className="list-disc list-inside mb-6 text-gray-300">
        {list.map((item, index) => (
          <li key={index} className="mb-2">{item}</li>
        ))}
      </ul>
      <Link
        to="/request-demo"
        className={`bg-gradient-to-r ${color} text-white px-6 py-3 rounded-full hover:opacity-90 transition-all duration-300 inline-flex items-center`}
      >
        Request Demo <FaArrowCircleRight className="ml-2" />
      </Link>
    </div>
  );

  const strategies = [
    {
      title: "Prevent Stockouts",
      icon: FaExclamationTriangle,
      color: "from-yellow-400 to-orange-600",
      content: renderStrategyCTA(
        "To prevent stockouts and optimize inventory levels, consider these actions:",
        [
          "Implement real-time inventory tracking for better visibility.",
          "Use AI to predict demand fluctuations and adjust stock levels accordingly.",
          "Automate reordering processes to ensure timely restocking."
        ],
        "from-yellow-400 to-orange-600"
      )
    },
    {
      title: "Reduce Excess Inventory",
      icon: FaChartLine,
      color: "from-blue-400 to-purple-600",
      content: renderStrategyCTA(
        "To reduce excess inventory and improve cash flow, you could:",
        [
          "Analyze slow-moving products and plan clearance strategies.",
          "Use dynamic pricing to accelerate the sale of overstocked items.",
          "Implement just-in-time inventory practices to minimize excess stock."
        ],
        "from-blue-400 to-purple-600"
      )
    }
  ];

  return (
    <section id="streamline-inventory" className="py-16 bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            Better Inventory Management
          </span>
        </motion.h2>
        <div className="flex flex-col md:flex-row items-center mb-12">
          <motion.div 
            className="md:w-1/3 mb-8 md:mb-0"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <FaBoxes className="text-8xl text-purple-500 mx-auto" />
          </motion.div>
          <motion.div 
            className="md:w-2/3"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <p className="text-xl text-gray-300 leading-relaxed">
              JaanchAI provides real-time inventory visibility and intelligent demand forecasting to help you optimize stock levels and avoid stockouts or overstocking. By analyzing sales trends, supplier lead times, and consumer behavior, JaanchAI enables you to make data-driven inventory decisions that minimize carrying costs and maximize product availability.
            </p>
          </motion.div>
        </div>
        <motion.div 
          className="bg-gray-800 rounded-xl p-8 shadow-2xl mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <h3 className="text-2xl font-bold mb-6 text-purple-400">Fashion Boutique Success Story</h3>
          <p className="text-lg mb-4 text-gray-300">
            <strong className="text-white">Problem:</strong> A fashion boutique struggled with frequent stockouts of popular items and excess inventory of slow-moving products. They relied on manual inventory tracking and gut-feel decisions, leading to lost sales and tied-up capital in underperforming inventory.
          </p>
          <p className="text-lg text-gray-300">
            <strong className="text-white">Solution:</strong> JaanchAI integrated with the boutique's point-of-sale and inventory management systems to provide real-time visibility into stock levels and sales velocity. By analyzing historical sales data and seasonal trends, JaanchAI generated accurate demand forecasts and recommended optimal stock levels for each product. This led to a <span className="text-purple-400 font-bold">40% reduction in stockouts</span>, a <span className="text-purple-400 font-bold">20% decrease in excess inventory</span>, and a <span className="text-purple-400 font-bold">15% increase in inventory turnover</span>, ultimately improving cash flow and customer satisfaction.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {strategies.map((strategy, index) => (
            <motion.div
              key={strategy.title}
              className={`bg-gradient-to-br ${strategy.color} p-1 rounded-xl shadow-lg`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 * index }}
              whileHover={{ scale: 1.05 }}
            >
              <div className="bg-gray-800 p-6 rounded-xl h-full flex flex-col justify-between">
                <div>
                  <strategy.icon className="text-5xl mb-4" />
                  <h4 className="text-xl font-bold mb-4">{strategy.title}</h4>
                </div>
                <button
                  onClick={() => handleModalOpen(strategy.title, strategy.content)}
                  className={`mt-4 bg-gradient-to-r ${strategy.color} text-white px-4 py-2 rounded-full hover:opacity-90 transition-all duration-300`}
                >
                  Learn More <FaArrowCircleRight className="inline ml-1" />
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {showModal && (
            <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
              {modalContent.content}
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default StreamlineInventory;