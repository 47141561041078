import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes, FaHome, FaCog, FaQuestionCircle, FaComments, FaRocket } from 'react-icons/fa';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { to: '/', icon: FaHome, text: 'Home' },
    { to: '/solutions', icon: FaCog, text: 'Solutions' },
    { to: '/faq', icon: FaQuestionCircle, text: 'FAQ' },
    { to: '/talk-to-expert', icon: FaComments, text: 'Talk to an Expert' },
  ];

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-black bg-opacity-80 backdrop-filter backdrop-blur-lg' : 'bg-transparent'}`}>
      <div className="container mx-auto px-4 py-4 flex items-center justify-between">
        <Link to="/" className="text-3xl font-bold tracking-wider">
          <motion.span 
            className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            Jaanch
          </motion.span>
          <motion.span 
            className="text-white"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            AI
          </motion.span>
        </Link>

        <nav className="hidden md:flex items-center space-x-8">
          <ul className="flex space-x-8 items-center">
            {navItems.map((item, index) => (
              <motion.li 
                key={item.to}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <Link 
                  to={item.to} 
                  className="text-white hover:text-purple-400 transition-colors duration-300 flex items-center text-sm uppercase tracking-wider"
                >
                  <item.icon className="mr-2" />
                  <span>{item.text}</span>
                </Link>
              </motion.li>
            ))}
          </ul>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.4 }}
          >
            <Link
              to="/request-demo"
              className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-6 rounded-full hover:from-blue-600 hover:to-purple-700 transition-all duration-300 flex items-center text-sm uppercase tracking-wider font-semibold"
            >
              <FaRocket className="mr-2" />
              <span>Request a Demo</span>
            </Link>
          </motion.div>
        </nav>

        <div className="md:hidden">
          <button onClick={toggleMobileMenu} className="text-2xl text-white">
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>

      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div 
            className="md:hidden bg-black bg-opacity-90 backdrop-filter backdrop-blur-lg text-white py-4"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <nav>
              <ul className="flex flex-col space-y-4 items-center">
                {navItems.map((item, index) => (
                  <motion.li 
                    key={item.to}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <Link 
                      to={item.to} 
                      className="text-lg hover:text-purple-400 transition-colors duration-300 flex items-center uppercase tracking-wider"
                      onClick={toggleMobileMenu}
                    >
                      <item.icon className="mr-2" />
                      <span>{item.text}</span>
                    </Link>
                  </motion.li>
                ))}
                <motion.li
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: navItems.length * 0.1 }}
                >
                  <Link
                    to="/request-demo"
                    className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-6 rounded-full hover:from-blue-600 hover:to-purple-700 transition-all duration-300 flex items-center uppercase tracking-wider font-semibold"
                    onClick={toggleMobileMenu}
                  >
                    <FaRocket className="mr-2" />
                    <span>Request a Demo</span>
                  </Link>
                </motion.li>
              </ul>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;