import React, { useState } from 'react';
import { FaChartLine, FaUsers, FaTags, FaBoxes, FaBell, FaUserCircle, FaBars, 
     FaRobot, FaCogs, FaEnvelope, FaWhatsapp, FaTelegramPlane, 
     FaChevronDown, FaChevronUp, FaHome } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import KPIContainer from './KPIContainer';
import KPI from './KPI';
import ChatbotFloater from './ChatbotFloater';

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isKPIBarCollapsed, setIsKPIBarCollapsed] = useState(false);
  const location = useLocation();

  const kpiData = {
    sales: '+15%',
    inventory: 'Optimal',
    customerSatisfaction: '4.8/5',
    revenueForecast: '+12%',
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const toggleProfileMenu = () => setIsProfileMenuOpen(!isProfileMenuOpen);
  const toggleKPIBar = () => setIsKPIBarCollapsed(!isKPIBarCollapsed);

  const navItems = [
    { to: '/dashboard', icon: FaHome, text: 'Dashboard' },
    { to: '/sales-analytics', icon: FaChartLine, text: 'Sales Analytics' },
    { to: '/customers-insights', icon: FaUsers, text: 'Customer Insights' },
    { to: '/pricingview', icon: FaTags, text: 'Pricing Strategies' },
    { to: '/inventory', icon: FaBoxes, text: 'Inventory Management' },
    // { to: '/database', icon: FaDatabase, text: 'Database Connections' },
    { to: '/automations', icon: FaRobot, text: 'Automations' },
    { to: '/settings', icon: FaCogs, text: 'Settings' },
  ];

  const automationSubItems = [
    { to: '/automations/whatsapp', icon: FaWhatsapp, text: 'WhatsApp' },
    { to: '/automations/email', icon: FaEnvelope, text: 'Email' },
    { to: '/automations/telegram', icon: FaTelegramPlane, text: 'Telegram' },
  ];

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className={`bg-gray-900 text-white fixed h-full z-20 transition-all duration-300 ${isSidebarOpen ? 'w-64' : 'w-20'}`}>
        <div className="p-4 flex items-center justify-between">
          {isSidebarOpen && <span className="text-2xl font-bold text-blue-400">JaanchAI</span>}
          <button onClick={toggleSidebar} className="text-white focus:outline-none p-2 rounded-full hover:bg-gray-800 transition-colors duration-300">
            <FaBars />
          </button>
        </div>
        <nav className="mt-8 space-y-2">
          {navItems.map((item) => (
            <div key={item.to}>
              <Link 
                to={item.to} 
                className={`flex items-center py-3 px-4 text-lg hover:bg-gray-800 transition-colors duration-300 ${
                  location.pathname === item.to ? 'bg-blue-600 text-white' : 'text-gray-300'
                }`}
              >
                <item.icon className={`text-xl ${isSidebarOpen ? 'mr-4' : 'mx-auto'}`} />
                {isSidebarOpen && <span>{item.text}</span>}
              </Link>
              {item.to === '/automations' && isSidebarOpen && (
                <div className="ml-8 mt-2 space-y-2">
                  {automationSubItems.map((subItem) => (
                    <Link 
                      key={subItem.to}
                      to={subItem.to} 
                      className={`flex items-center py-2 px-4 text-sm hover:bg-gray-800 transition-colors duration-300 ${
                        location.pathname === subItem.to ? 'bg-blue-600 text-white' : 'text-gray-400'
                      }`}
                    >
                      <subItem.icon className="mr-3 text-lg" />
                      <span>{subItem.text}</span>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-grow transition-all duration-300" style={{ marginLeft: isSidebarOpen ? '16rem' : '5rem' }}>
        {/* Navbar */}
        <header className="bg-white shadow-md z-10">
          <div className="flex items-center justify-between p-4">
            <h1 className="text-2xl font-bold text-gray-800">JaanchAI Dashboard</h1>
            <div className="flex items-center space-x-4">
              <FaBell className="text-gray-600 text-2xl cursor-pointer hover:text-blue-500 transition-colors duration-300" />
              <div className="relative">
                <FaUserCircle 
                  className="text-gray-600 text-2xl cursor-pointer hover:text-blue-500 transition-colors duration-300" 
                  onClick={toggleProfileMenu} 
                />
                {isProfileMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-20">
                    <Link to="/profile" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition-colors duration-300">
                      Profile
                    </Link>
                    <Link to="/settings" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition-colors duration-300">
                      Settings
                    </Link>
                    <Link to="/logout" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition-colors duration-300">
                      Logout
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>

        {/* KPI Bar */}
        <div className="bg-gray-800 shadow-md p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">KPIs</h2>
            <button 
              onClick={toggleKPIBar} 
              className="text-white focus:outline-none"
            >
              {isKPIBarCollapsed ? <FaChevronDown /> : <FaChevronUp />}
            </button>
          </div>
          {!isKPIBarCollapsed && (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <KPI id={1} name="Sales" />
              <KPI id={2} name="Inventory" />
              <KPI id={3} name="Revenue" />
              <KPI id={4} name="Customer Satisfaction" />
            </div>
          )}
        </div>

        {/* Content */}
        <main className="p-8 flex-grow">
          <section id="kpi-container" className="bg-white rounded-lg shadow-md p-6 h-full">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">KPI Analysis</h2>
            <KPIContainer />
            <ChatbotFloater kpiData={kpiData} />
          </section>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;