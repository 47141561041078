import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChartLine, FaArrowCircleRight, FaUsers, FaStar, FaHeart } from 'react-icons/fa';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const BoostSales = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const renderStrategyContent = (title, list, color) => (
    <div>
      <p className="mb-4 text-gray-300">{title}</p>
      <ul className="list-disc list-inside mb-6 text-gray-300">
        {list.map((item, index) => (
          <li key={index} className="mb-2">{item}</li>
        ))}
      </ul>
      <Link
        to="/request-demo"
        className={`bg-gradient-to-r ${color} text-white px-6 py-3 rounded-full hover:opacity-90 transition-all duration-300 inline-flex items-center`}
      >
        Request Demo <FaArrowCircleRight className="ml-2" />
      </Link>
    </div>
  );

  const strategies = [
    {
      title: "Focus on Retention",
      icon: FaUsers,
      color: "from-green-400 to-green-600",
      content: renderStrategyContent(
        "To reduce the churn rate and boost retention, consider the following strategies:",
        [
          "Implement targeted retention campaigns for high-risk customers.",
          "Offer loyalty programs or incentives for long-term customers.",
          "Enhance customer support with AI-driven solutions."
        ],
        "from-green-400 to-green-600"
      )
    },
    {
      title: "Enhance Satisfaction",
      icon: FaStar,
      color: "from-blue-400 to-blue-600",
      content: renderStrategyContent(
        "To enhance customer satisfaction, you could:",
        [
          "Collect customer feedback through surveys and implement changes.",
          "Personalize customer interactions using AI-driven insights.",
          "Provide quicker resolutions to customer issues with a dedicated support team."
        ],
        "from-blue-400 to-blue-600"
      )
    },
    {
      title: "Increase Lifetime Value",
      icon: FaHeart,
      color: "from-purple-400 to-purple-600",
      content: renderStrategyContent(
        "Increase customer lifetime value with a tailored loyalty program:",
        [
          "Develop personalized rewards based on customer preferences and behavior.",
          "Implement a tiered loyalty system to encourage continued engagement.",
          "Use predictive analytics to anticipate and fulfill customer needs."
        ],
        "from-purple-400 to-purple-600"
      )
    }
  ];

  return (
    <section id="boost-sales" className="py-16 bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">
            Boost Your Sales
          </span>
        </motion.h2>
        <div className="flex flex-col md:flex-row items-center mb-12">
          <motion.div 
            className="md:w-1/3 mb-8 md:mb-0"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <FaChartLine className="text-8xl text-blue-500 mx-auto" />
          </motion.div>
          <motion.div 
            className="md:w-2/3"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <p className="text-xl text-gray-300 leading-relaxed">
              JaanchAI leverages advanced analytics and customer segmentation to identify opportunities for increasing sales and customer retention. By providing personalized product recommendations and targeted promotions, JaanchAI helps you maximize customer lifetime value and drive revenue growth.
            </p>
          </motion.div>
        </div>
        <motion.div 
          className="bg-gray-800 rounded-xl p-8 shadow-2xl mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <h3 className="text-2xl font-bold mb-6 text-blue-400">Cosmetics E-commerce Success Story</h3>
          <p className="text-lg mb-4 text-gray-300">
            <strong className="text-white">Problem:</strong> A cosmetics e-commerce store was experiencing high customer churn rates and struggling to increase sales. They had a broad customer base but lacked insights into customer preferences and behavior.
          </p>
          <p className="text-lg text-gray-300">
            <strong className="text-white">Solution:</strong> JaanchAI analyzed the store's customer data and identified distinct customer segments. By understanding the unique preferences of each segment, the store created targeted campaigns and personalized recommendations. This led to a <span className="text-green-400 font-bold">30% increase in customer retention</span> and a <span className="text-green-400 font-bold">20% boost in sales revenue</span> within three months.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {strategies.map((strategy, index) => (
            <motion.div
              key={strategy.title}
              className={`bg-gradient-to-br ${strategy.color} p-1 rounded-xl shadow-lg`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 * index }}
              whileHover={{ scale: 1.05 }}
            >
              <div className="bg-gray-800 p-6 rounded-xl h-full flex flex-col justify-between">
                <div>
                  <strategy.icon className="text-5xl mb-4" />
                  <h4 className="text-xl font-bold mb-4">{strategy.title}</h4>
                </div>
                <button
                  onClick={() => handleModalOpen(strategy.title, strategy.content)}
                  className={`mt-4 bg-gradient-to-r ${strategy.color} text-white px-4 py-2 rounded-full hover:opacity-90 transition-all duration-300`}
                >
                  Learn More <FaArrowCircleRight className="inline ml-1" />
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {showModal && (
            <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
              {modalContent.content}
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default BoostSales;