import React from 'react';
import { motion } from 'framer-motion';
import { FaRobot, FaChartLine, FaBoxes, FaShoppingCart, FaTruck, FaCog } from 'react-icons/fa';

const JaanchAIFeatures = () => {
  const features = [
    { name: 'AI-Powered Decision Making', icon: FaRobot, description: 'Automate complex business decisions with minimal manual intervention' },
    { name: 'Real-Time Analytics', icon: FaChartLine, description: 'Get actionable insights and adapt quickly to market conditions' },
    { name: 'Dynamic Optimization', icon: FaBoxes, description: 'Optimize pricing, promotions, and inventory in real-time' },
    { name: 'E-commerce Excellence', icon: FaShoppingCart, description: 'Boost conversion rates and profit margins with smart strategies' },
    { name: 'Logistics Mastery', icon: FaTruck, description: 'Streamline operations and perfect your supply chain management' },
    { name: 'Seamless Integration', icon: FaCog, description: 'Easily connect with your existing systems and customize your dashboard' },
  ];

  return (
    <section className="bg-gray-900 py-20 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black"></div>
      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-bold mb-6">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
              Revolutionize Your Business with JaanchAI
            </span>
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Harness the power of real-time AI-driven decision-making to optimize your e-commerce and logistics operations. Automate complex decisions, boost efficiency, and maximize profits with JaanchAI's comprehensive solution.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-center mb-12"
        >
          <h3 className="text-3xl font-semibold mb-8 text-white">Key Features</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {features.map((feature, index) => (
              <motion.div
                key={feature.name}
                className="bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300"
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <feature.icon className="text-5xl mb-4 mx-auto text-blue-400" />
                <h4 className="text-xl font-semibold mb-2 text-white">{feature.name}</h4>
                <p className="text-gray-300">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <motion.div
          className="text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <a 
            href="request-demo" 
            className="inline-flex items-center bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-purple-700 transition-all duration-300"
          >
            Experience the Future of Business Intelligence
            <FaChartLine className="ml-2" />
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default JaanchAIFeatures;