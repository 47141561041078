import React from 'react';
import { motion } from 'framer-motion';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaHeart } from 'react-icons/fa';

const Footer = () => {
  const socialLinks = [
    { icon: FaFacebook, href: "https://facebook.com" },
    { icon: FaTwitter, href: "https://twitter.com" },
    { icon: FaLinkedin, href: "https://linkedin.com" },
    { icon: FaInstagram, href: "https://instagram.com" }
  ];

  const footerLinks = [
    { text: "Privacy", href: "#privacy" },
    { text: "Terms", href: "#terms" },
    { text: "Contact Us", href: "#contact" }
  ];

  return (
    <footer className="bg-gray-900 py-12 text-gray-300 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black"></div>
      <div className="container mx-auto px-4 relative z-10">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-8"
        >
          <h2 className="text-2xl font-bold mb-4">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
              Stay Connected with Us
            </span>
          </h2>
          <div className="flex justify-center space-x-6 mt-4">
            {socialLinks.map((link, index) => (
              <motion.a
                key={index}
                href={link.href}
                className="text-gray-400 hover:text-white transition-colors duration-300"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <link.icon className="text-3xl" />
              </motion.a>
            ))}
          </div>
        </motion.div>

        <motion.nav 
          className="mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <ul className="flex flex-wrap justify-center space-x-6">
            {footerLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href} className="hover:text-white transition-colors duration-300 text-sm">
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </motion.nav>

        <motion.div 
          className="text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <p className="mb-4 font-medium">© 2024 Jaanch Inc.</p>
          <p className="mb-4 text-sm">
            Email: <a href="mailto:gopal@jaanch.ai" className="hover:text-white transition-colors duration-300">gopal@jaanch.ai</a>
          </p>
          <p className="text-sm text-gray-400 flex items-center justify-center">
            Made in India with <FaHeart className="text-red-500 mx-1" /> by innovative minds.
          </p>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;