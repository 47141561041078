import React, { useState } from 'react';
import { FaWhatsapp, FaTelegramPlane, FaEnvelope, FaArrowCircleRight } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import Modal from './Modal';

const Automation = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const navigate = useNavigate();

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleWhatsAppAutomation = () => {
    navigate('/whatsapp-chat');
  };

  const renderWhatsAppAutomation = () => (
    <div className="text-black">
      <p className="mb-4">Set up an automation to send WhatsApp notifications:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Message Content</label>
          <textarea className="w-full bg-gray-200 border-gray-400 rounded-md p-2" placeholder="Enter the message content"></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Trigger Event</label>
          <select className="w-full bg-gray-200 border-gray-400 rounded-md p-2">
            <option>Sales Cross $10,000</option>
            <option>Inventory Drops Below 50 Units</option>
            <option>New Customer Sign-Up</option>
          </select>
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="button"
          className="bg-gradient-to-r from-green-600 to-green-800 text-white px-6 py-2 rounded-full hover:from-green-700 hover:to-green-900 transition-all duration-300 shadow-lg"
          onClick={handleWhatsAppAutomation}
        >
          Set Up Automation
        </motion.button>
      </form>
    </div>
  );

  const renderTelegramAutomation = () => (
    <div className="text-black">
      <p className="mb-4">Set up an automation to send Telegram messages:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Message Content</label>
          <textarea className="w-full bg-gray-200 border-gray-400 rounded-md p-2" placeholder="Enter the message content"></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Trigger Event</label>
          <select className="w-full bg-gray-200 border-gray-400 rounded-md p-2">
            <option>Daily Sales Report</option>
            <option>Weekly Inventory Summary</option>
            <option>Monthly Customer Feedback</option>
          </select>
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="submit"
          className="bg-gradient-to-r from-blue-600 to-blue-800 text-white px-6 py-2 rounded-full hover:from-blue-700 hover:to-blue-900 transition-all duration-300 shadow-lg"
        >
          Set Up Automation
        </motion.button>
      </form>
    </div>
  );

  const renderEmailAutomation = () => (
    <div className="text-black">
      <p className="mb-4">Set up an automation to send email reports:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Subject</label>
          <input type="text" className="w-full bg-gray-200 border-gray-400 rounded-md p-2" placeholder="Enter email subject" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Email Content</label>
          <textarea className="w-full bg-gray-200 border-gray-400 rounded-md p-2" placeholder="Enter the email content"></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Trigger Event</label>
          <select className="w-full bg-gray-200 border-gray-400 rounded-md p-2">
            <option>End of Day Sales Summary</option>
            <option>Weekly Performance Report</option>
            <option>Monthly Inventory Report</option>
          </select>
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="submit"
          className="bg-gradient-to-r from-purple-600 to-purple-800 text-white px-6 py-2 rounded-full hover:from-purple-700 hover:to-purple-900 transition-all duration-300 shadow-lg"
        >
          Set Up Automation
        </motion.button>
      </form>
    </div>
  );

  const CardWrapper = ({ children, className }) => (
    <motion.div
      className={`p-6 rounded-xl shadow-lg ${className}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.02 }}
    >
      {children}
    </motion.div>
  );

  const ActionButton = ({ onClick, className, children }) => (
    <motion.button
      whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgba(255,255,255,0.5)" }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`px-4 py-2 rounded-full transition-all duration-300 shadow-lg ${className}`}
    >
      {children} <FaArrowCircleRight className="inline ml-1" />
    </motion.button>
  );

  return (
    <DashboardLayout>
      <div className="p-6 bg-gray-900 rounded-lg shadow-xl">
        <motion.h2
          className="text-3xl font-bold text-white mb-6 flex items-center"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <FaArrowCircleRight className="text-green-500 mr-3" /> Automations
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <CardWrapper className="bg-gradient-to-br from-green-500 to-green-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaWhatsapp className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-green-200">WhatsApp Notifications</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('WhatsApp Automation', renderWhatsAppAutomation())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-green-500 hover:from-green-600 hover:to-green-700"
              >
                Set Up
              </ActionButton>
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gradient-to-br from-blue-500 to-blue-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaTelegramPlane className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-blue-200">Telegram Messages</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Telegram Automation', renderTelegramAutomation())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-blue-500 hover:from-blue-600 hover:to-blue-700"
              >
                Set Up
              </ActionButton>
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gradient-to-br from-purple-500 to-purple-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaEnvelope className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-purple-200">Email Reports</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Email Automation', renderEmailAutomation())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-purple-500 hover:from-purple-600 hover:to-purple-700"
              >
                Set Up
              </ActionButton>
            </div>
          </CardWrapper>
        </div>

        <AnimatePresence>
          {showModal && (
            <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
              {modalContent.content}
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </DashboardLayout>
  );
};

export default Automation;
