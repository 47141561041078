import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { motion, AnimatePresence } from 'framer-motion';
import { FaArrowUp, FaArrowDown, FaDollarSign, FaChartLine, FaArrowCircleRight } from 'react-icons/fa';
import DashboardLayout from './DashboardLayout';
import Modal from './Modal';

const SalesAnalytics = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const salesData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sales',
        data: [12000, 15000, 17000, 22000, 25000, 27000],
        fill: false,
        borderColor: '#4CAF50',
        tension: 0.1,
      },
    ],
  };

  const salesGrowth = 12.5;
  const topProduct = 'Smartphone Pro Max';

  const renderGrowthStrategy = () => (
    <div>
      <p className="mb-4 text-gray-300">To sustain the growth rate of {salesGrowth}%, consider the following strategies:</p>
      <ul className="list-disc list-inside mb-4 text-gray-300">
        <li>Increase marketing spend on high-performing channels.</li>
        <li>Expand the top product's availability in more regions.</li>
        <li>Introduce loyalty programs for frequent buyers.</li>
      </ul>
      <motion.button 
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="bg-gradient-to-r from-green-600 to-green-800 text-white px-6 py-2 rounded-full hover:from-green-700 hover:to-green-900 transition-all duration-300 shadow-lg"
      >
        Implement Strategy
      </motion.button>
    </div>
  );

  const renderTopProductCampaign = () => (
    <div>
      <p className="mb-4 text-gray-300">Leverage the success of the {topProduct} by launching a new marketing campaign:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-300">Select Region</label>
          <select className="w-full bg-gray-700 border-gray-600 text-white rounded-md p-2">
            <option>North America</option>
            <option>Europe</option>
            <option>Asia-Pacific</option>
          </select>
        </div>
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="submit" 
          className="bg-gradient-to-r from-yellow-600 to-yellow-800 text-white px-6 py-2 rounded-full hover:from-yellow-700 hover:to-yellow-900 transition-all duration-300 shadow-lg"
        >
          Launch Campaign
        </motion.button>
      </form>
    </div>
  );

  const renderSalesDropAction = () => (
    <div>
      <p className="mb-4 text-gray-300">Address the sales drop in the Southwest region:</p>
      <ul className="list-disc list-inside mb-4 text-gray-300">
        <li>Conduct a market analysis to understand the decline.</li>
        <li>Offer region-specific discounts and promotions.</li>
        <li>Engage local influencers to boost brand awareness.</li>
      </ul>
      <motion.button 
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="bg-gradient-to-r from-red-600 to-red-800 text-white px-6 py-2 rounded-full hover:from-red-700 hover:to-red-900 transition-all duration-300 shadow-lg"
      >
        Execute Plan
      </motion.button>
    </div>
  );

  const CardWrapper = ({ children, className }) => (
    <motion.div 
      className={`p-6 rounded-xl shadow-lg ${className}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.02 }}
    >
      {children}
    </motion.div>
  );

  const ActionButton = ({ onClick, className, children }) => (
    <motion.button
      whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgba(255,255,255,0.5)" }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`px-4 py-2 rounded-full transition-all duration-300 shadow-lg ${className}`}
    >
      {children} <FaArrowCircleRight className="inline ml-1" />
    </motion.button>
  );

  return (
    <DashboardLayout>
      <div className="p-6 bg-gray-900 rounded-lg shadow-xl">
        <motion.h2 
          className="text-3xl font-bold text-white mb-6 flex items-center"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <FaChartLine className="text-green-500 mr-3" /> Sales Analytics
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <CardWrapper className="bg-gradient-to-br from-blue-500 to-blue-700">
            <div className="flex items-center">
              <FaDollarSign className="text-white text-4xl mr-4" />
              <div>
                <p className="text-lg font-semibold text-blue-200">Total Sales</p>
                <p className="text-3xl font-bold text-white">$27,000</p>
              </div>
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gradient-to-br from-green-500 to-green-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaArrowUp className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-green-200">Growth Rate</p>
                  <p className="text-3xl font-bold text-white">{salesGrowth}%</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Sustain Growth Strategy', renderGrowthStrategy())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-green-500 hover:from-green-600 hover:to-green-700"
              >
                Sustain Growth
              </ActionButton>
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gradient-to-br from-yellow-500 to-yellow-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaChartLine className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-yellow-200">Top Product</p>
                  <p className="text-2xl font-bold text-white">{topProduct}</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Top Product Campaign', renderTopProductCampaign())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-yellow-500 hover:from-yellow-600 hover:to-yellow-700"
              >
                Promote Product
              </ActionButton>
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gradient-to-br from-red-500 to-red-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaArrowDown className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-red-200">Sales Drop (Region)</p>
                  <p className="text-2xl font-bold text-white">-8% (Southwest)</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Address Sales Drop', renderSalesDropAction())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-red-500 hover:from-red-600 hover:to-red-700"
              >
                Address Drop
              </ActionButton>
            </div>
          </CardWrapper>
        </div>

        <CardWrapper className="bg-gray-800">
          <h3 className="text-2xl font-semibold text-white mb-4">Sales Over Time</h3>
          <Line 
            data={salesData} 
            options={{
              scales: {
                x: { ticks: { color: 'white' } },
                y: { ticks: { color: 'white' } }
              },
              plugins: {
                legend: { labels: { color: 'white' } }
              }
            }} 
          />
        </CardWrapper>

        <AnimatePresence>
          {showModal && (
            <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
              {modalContent.content}
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </DashboardLayout>
  );
};

export default SalesAnalytics;