import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCogs, FaArrowCircleRight, FaRobot, FaChartLine } from 'react-icons/fa';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const EnhanceEfficiency = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const renderStrategyCTA = (title, list, color) => (
    <div>
      <p className="mb-4 text-gray-300">{title}</p>
      <ul className="list-disc list-inside mb-6 text-gray-300">
        {list.map((item, index) => (
          <li key={index} className="mb-2">{item}</li>
        ))}
      </ul>
      <Link
        to="/request-demo"
        className={`bg-gradient-to-r ${color} text-white px-6 py-3 rounded-full hover:opacity-90 transition-all duration-300 inline-flex items-center`}
      >
        Request Demo <FaArrowCircleRight className="ml-2" />
      </Link>
    </div>
  );

  const strategies = [
    {
      title: "Implement Automation",
      icon: FaRobot,
      color: "from-green-400 to-teal-600",
      content: renderStrategyCTA(
        "To improve efficiency and reduce manual workload, consider these automation strategies:",
        [
          "Automate order processing to streamline fulfillment.",
          "Use AI to update inventory levels in real-time.",
          "Generate shipping labels with minimal manual intervention."
        ],
        "from-green-400 to-teal-600"
      )
    },
    {
      title: "Enhance Efficiency",
      icon: FaChartLine,
      color: "from-blue-400 to-indigo-600",
      content: renderStrategyCTA(
        "To further enhance operational efficiency, you could:",
        [
          "Implement AI-driven recommendations for inventory management.",
          "Streamline product listings and update them automatically across multiple platforms.",
          "Leverage AI to optimize order fulfillment and reduce processing time."
        ],
        "from-blue-400 to-indigo-600"
      )
    }
  ];

  return (
    <section id="enhance-efficiency" className="py-16 bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-pink-600">
            Work Smarter, Not Harder
          </span>
        </motion.h2>
        <div className="flex flex-col md:flex-row items-center mb-12">
          <motion.div 
            className="md:w-1/3 mb-8 md:mb-0"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <FaCogs className="text-8xl text-red-500 mx-auto" />
          </motion.div>
          <motion.div 
            className="md:w-2/3"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <p className="text-xl text-gray-300 leading-relaxed">
              JaanchAI automates repetitive tasks and provides intelligent recommendations, allowing your team to focus on high-value activities. It streamlines product listings, order management, and fulfillment processes, making your business operate more efficiently and effectively.
            </p>
          </motion.div>
        </div>
        <motion.div 
          className="bg-gray-800 rounded-xl p-8 shadow-2xl mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <h3 className="text-2xl font-bold mb-6 text-red-400">Handmade Jewelry Store Success Story</h3>
          <p className="text-lg mb-4 text-gray-300">
            <strong className="text-white">Problem:</strong> A handmade jewelry store was struggling with manual order processing and inventory management, limiting their ability to focus on creating new designs and growing the business.
          </p>
          <p className="text-lg text-gray-300">
            <strong className="text-white">Solution:</strong> By implementing JaanchAI, the jewelry store automated their order processing and inventory management. This automation <span className="text-red-400 font-bold">reduced manual order processing time by 80%</span>, freeing up the owner to focus on designing new products and marketing their business. As a result, the store saw a <span className="text-red-400 font-bold">25% increase in sales</span> and improved customer satisfaction due to faster order fulfillment.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {strategies.map((strategy, index) => (
            <motion.div
              key={strategy.title}
              className={`bg-gradient-to-br ${strategy.color} p-1 rounded-xl shadow-lg`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 * index }}
              whileHover={{ scale: 1.05 }}
            >
              <div className="bg-gray-800 p-6 rounded-xl h-full flex flex-col justify-between">
                <div>
                  <strategy.icon className="text-5xl mb-4" />
                  <h4 className="text-xl font-bold mb-4">{strategy.title}</h4>
                </div>
                <button
                  onClick={() => handleModalOpen(strategy.title, strategy.content)}
                  className={`mt-4 bg-gradient-to-r ${strategy.color} text-white px-4 py-2 rounded-full hover:opacity-90 transition-all duration-300`}
                >
                  Learn More <FaArrowCircleRight className="inline ml-1" />
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {showModal && (
            <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
              {modalContent.content}
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default EnhanceEfficiency;