import React from 'react';
import { useDrag } from 'react-dnd';
import { motion } from 'framer-motion';
import { FaChartLine, FaChartBar, FaChartPie, FaPercentage, FaDollarSign } from 'react-icons/fa';

const KPI = ({ id, name, category }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'KPI',
    item: { id, name, category },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const getIcon = () => {
    switch (category) {
      case 'sales':
        return <FaChartLine className="text-blue-400" />;
      case 'performance':
        return <FaChartBar className="text-green-400" />;
      case 'finance':
        return <FaDollarSign className="text-yellow-400" />;
      case 'efficiency':
        return <FaPercentage className="text-purple-400" />;
      default:
        return <FaChartPie className="text-red-400" />;
    }
  };

  const getGradient = () => {
    switch (category) {
      case 'sales':
        return 'from-blue-500 to-blue-700';
      case 'performance':
        return 'from-green-500 to-green-700';
      case 'finance':
        return 'from-yellow-500 to-yellow-700';
      case 'efficiency':
        return 'from-purple-500 to-purple-700';
      default:
        return 'from-red-500 to-red-700';
    }
  };

  return (
    <motion.div
      ref={drag}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{ 
        opacity: isDragging ? 0.5 : 1,
        cursor: 'grab'
      }}
      className={`p-3 bg-gradient-to-r ${getGradient()} rounded-lg shadow-lg mb-3 text-white flex items-center justify-between`}
    >
      <div className="flex items-center">
        <div className="mr-3 text-xl">
          {getIcon()}
        </div>
        <span className="font-semibold">{name}</span>
      </div>
      <div className="text-xs opacity-75">
        {category}
      </div>
    </motion.div>
  );
};

export default KPI;