import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTags, FaArrowCircleRight, FaChartLine, FaPercent } from 'react-icons/fa';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const OptimizePricing = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const renderStrategyCTA = (title, list, color) => (
    <div>
      <p className="mb-4 text-gray-300">{title}</p>
      <ul className="list-disc list-inside mb-6 text-gray-300">
        {list.map((item, index) => (
          <li key={index} className="mb-2">{item}</li>
        ))}
      </ul>
      <Link
        to="/request-demo"
        className={`bg-gradient-to-r ${color} text-white px-6 py-3 rounded-full hover:opacity-90 transition-all duration-300 inline-flex items-center`}
      >
        Request Demo <FaArrowCircleRight className="ml-2" />
      </Link>
    </div>
  );

  const strategies = [
    {
      title: "Optimize Pricing Strategy",
      icon: FaChartLine,
      color: "from-green-400 to-green-600",
      content: renderStrategyCTA(
        "To optimize pricing and increase margins, explore the following strategies:",
        [
          "Implement dynamic pricing models based on real-time market trends.",
          "Segment customers by price sensitivity to offer targeted discounts.",
          "Use AI to predict optimal pricing for upcoming sales seasons."
        ],
        "from-green-400 to-green-600"
      )
    },
    {
      title: "Analyze Promotions",
      icon: FaPercent,
      color: "from-blue-400 to-blue-600",
      content: renderStrategyCTA(
        "To analyze and optimize your promotional efforts, consider these actions:",
        [
          "Identify the promotions that yield the highest ROI.",
          "Reduce deep discounting while maintaining customer satisfaction.",
          "Leverage AI to predict the effectiveness of upcoming promotional campaigns."
        ],
        "from-blue-400 to-blue-600"
      )
    }
  ];

  return (
    <section id="optimize-pricing" className="py-16 bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-600">
            Smart Pricing & Promos
          </span>
        </motion.h2>
        <div className="flex flex-col md:flex-row items-center mb-12">
          <motion.div 
            className="md:w-1/3 mb-8 md:mb-0"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <FaTags className="text-8xl text-green-500 mx-auto" />
          </motion.div>
          <motion.div 
            className="md:w-2/3"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <p className="text-xl text-gray-300 leading-relaxed">
              JaanchAI utilizes AI-powered pricing optimization and promotional effectiveness analysis to help you stay competitive and profitable. By continuously monitoring market trends, competitor pricing, and consumer demand, JaanchAI provides data-driven insights and recommendations for optimal pricing and promotional strategies.
            </p>
          </motion.div>
        </div>
        <motion.div 
          className="bg-gray-800 rounded-xl p-8 shadow-2xl mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <h3 className="text-2xl font-bold mb-6 text-green-400">Home Appliance Retailer Success Story</h3>
          <p className="text-lg mb-4 text-gray-300">
            <strong className="text-white">Problem:</strong> A home appliance retailer was facing intense competition and struggling to maintain profitability. They often resorted to deep discounts to attract customers but failed to understand the impact on overall profitability.
          </p>
          <p className="text-lg text-gray-300">
            <strong className="text-white">Solution:</strong> JaanchAI analyzed the retailer's pricing and promotional data, considering factors such as competitor pricing, customer price sensitivity, and seasonal demand. By providing optimized pricing recommendations and identifying the most effective promotional strategies, JaanchAI helped the retailer <span className="text-green-400 font-bold">increase gross margins by 15%</span> while maintaining competitive prices. The retailer also saw a <span className="text-green-400 font-bold">25% improvement in promotional ROI</span>, ensuring that their marketing efforts were driving profitable growth.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {strategies.map((strategy, index) => (
            <motion.div
              key={strategy.title}
              className={`bg-gradient-to-br ${strategy.color} p-1 rounded-xl shadow-lg`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 * index }}
              whileHover={{ scale: 1.05 }}
            >
              <div className="bg-gray-800 p-6 rounded-xl h-full flex flex-col justify-between">
                <div>
                  <strategy.icon className="text-5xl mb-4" />
                  <h4 className="text-xl font-bold mb-4">{strategy.title}</h4>
                </div>
                <button
                  onClick={() => handleModalOpen(strategy.title, strategy.content)}
                  className={`mt-4 bg-gradient-to-r ${strategy.color} text-white px-4 py-2 rounded-full hover:opacity-90 transition-all duration-300`}
                >
                  Learn More <FaArrowCircleRight className="inline ml-1" />
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {showModal && (
            <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
              {modalContent.content}
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default OptimizePricing;