import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-scroll';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { FaChartLine, FaDollarSign, FaBoxes, FaCog } from 'react-icons/fa';
import BoostSales from './BoostSales';
import OptimizePricing from './OptimizePricing';
import StreamlineInventory from './StreamlineInventory';
import EnhanceEfficiency from './EnhanceEfficiency';

const Solutions = () => {
  const [activeSection, setActiveSection] = useState('boost-sales');
  const { scrollY } = useViewportScroll();
  const navOpacity = useTransform(scrollY, [0, 100], [0, 1]);

  const sections = useMemo(() => [
    { id: 'boost-sales', title: 'Boost Sales', icon: FaChartLine, color: 'from-blue-400 to-blue-600' },
    { id: 'optimize-pricing', title: 'Optimize Pricing', icon: FaDollarSign, color: 'from-green-400 to-green-600' },
    { id: 'streamline-inventory', title: 'Streamline Inventory', icon: FaBoxes, color: 'from-purple-400 to-purple-600' },
    { id: 'enhance-efficiency', title: 'Enhance Efficiency', icon: FaCog, color: 'from-red-400 to-red-600' },
  ], []);

  useEffect(() => {
    const handleScroll = () => {
      const currentSection = sections.find(section => {
        const element = document.getElementById(section.id);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      if (currentSection) setActiveSection(currentSection.id);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [
    sections,
  ]);

  return (
    <div className="relative bg-gray-900 text-white">
      <motion.nav 
        className="fixed top-16 left-0 right-0 z-50 bg-gray-800 bg-opacity-90 backdrop-filter backdrop-blur-lg py-4 shadow-lg"
        style={{ opacity: navOpacity }}
      >
        <div className="container mx-auto flex justify-center flex-wrap gap-4">
          {sections.map((section) => (
            <Link
              key={section.id}
              to={section.id}
              smooth={true}
              duration={600}
              className={`cursor-pointer py-2 px-4 rounded-full transition-all duration-300 flex items-center ${
                activeSection === section.id
                  ? `bg-gradient-to-r ${section.color} text-white`
                  : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
              }`}
            >
              <section.icon className="mr-2" />
              {section.title}
            </Link>
          ))}
        </div>
      </motion.nav>
      <div className="pt-32 md:pt-24">
        {sections.map((section, index) => (
          <motion.div
            key={section.id}
            id={section.id}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: index * 0.2 }}
            className="py-16"
          >
            <div className="container mx-auto px-4">
              <h2 className={`text-4xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r ${section.color}`}>
                {section.title}
              </h2>
              {section.id === 'boost-sales' && <BoostSales />}
              {section.id === 'optimize-pricing' && <OptimizePricing />}
              {section.id === 'streamline-inventory' && <StreamlineInventory />}
              {section.id === 'enhance-efficiency' && <EnhanceEfficiency />}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Solutions;