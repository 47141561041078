import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaRocket, FaUser, FaEnvelope, FaBuilding, FaCommentAlt } from 'react-icons/fa';

const DemoRequestForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com)(?!yahoo\.com)(?!hotmail\.com)(?!.*\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      setSubmitStatus('error');
      setIsSubmitting(false);
      return;
    }

    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    console.log('Form submitted:', formData);
    setFormData({ name: '', email: '', company: '', message: '' });
    setIsSubmitting(false);
    setSubmitStatus('success');
  };

  const inputFields = [
    { name: 'name', label: 'Name', placeholder: 'Your Name', icon: FaUser, type: 'text' },
    { name: 'email', label: 'Business Email', placeholder: 'your@business.com', icon: FaEnvelope, type: 'email' },
    { name: 'company', label: 'Company', placeholder: 'Your Company', icon: FaBuilding, type: 'text' },
  ];

  return (
    <section className="bg-gray-900 py-20 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-600 to-purple-800 opacity-20"></div>
      <div className="container mx-auto px-4 max-w-2xl relative z-10">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl font-bold mb-4 text-white">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">
              Request a Demo
            </span>
          </h2>
          <p className="text-xl mb-8 text-gray-300">
            Experience the power of JaanchAI firsthand. Let's revolutionize your business together.
          </p>
        </motion.div>
        
        <motion.form
          onSubmit={handleSubmit}
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="bg-gray-800 shadow-2xl rounded-lg p-8"
        >
          {inputFields.map((field) => (
            <div key={field.name} className="mb-6">
              <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor={field.name}>
                {field.label}
              </label>
              <div className="relative">
                <field.icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  className="bg-gray-700 appearance-none border border-gray-600 rounded w-full py-3 px-4 pl-10 text-gray-300 leading-tight focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition duration-300"
                  id={field.name}
                  type={field.type}
                  placeholder={field.placeholder}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          ))}
          <div className="mb-6">
            <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="message">
              Message
            </label>
            <div className="relative">
              <FaCommentAlt className="absolute left-3 top-3 text-gray-400" />
              <textarea
                className="bg-gray-700 appearance-none border border-gray-600 rounded w-full py-3 px-4 pl-10 text-gray-300 leading-tight focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition duration-300"
                id="message"
                placeholder="How can we help you?"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <motion.button
              className={`bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-3 px-8 rounded-full focus:outline-none focus:shadow-outline transition duration-300 flex items-center ${
                isSubmitting ? 'opacity-75 cursor-not-allowed' : 'hover:from-blue-600 hover:to-purple-700'
              }`}
              type="submit"
              disabled={isSubmitting}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {isSubmitting ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Submitting...
                </span>
              ) : (
                <>
                  Request Demo <FaRocket className="ml-2" />
                </>
              )}
            </motion.button>
          </div>
        </motion.form>

        {submitStatus === 'error' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-4 p-4 bg-red-500 text-white rounded-lg text-center"
          >
            Please use a valid business email address.
          </motion.div>
        )}

        {submitStatus === 'success' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-4 p-4 bg-green-500 text-white rounded-lg text-center"
          >
            Thank you for your request! We'll be in touch soon.
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default DemoRequestForm;