import React, { useEffect, useState } from 'react';
import { FaChartLine, FaUsers, FaPercentage, FaDollarSign, FaBoxes, FaLink, FaArrowRight } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const JaanchAIOverview = () => {
  const [activeCase, setActiveCase] = useState(0);

  const connectedSystems = [
    { name: "E-commerce Platforms", description: "Seamless integration with Shopify, Magento, and other major platforms." },
    { name: "CRM Systems", description: "Connect with customer relationship management tools for comprehensive data analysis." },
    { name: "Inventory Management", description: "Real-time sync with warehouse and inventory systems for optimal stock levels." },
    { name: "Logistics Networks", description: "Integration with supply chain and logistics platforms for end-to-end visibility." },
    { name: "Competitor Pricing APIs", description: "Real-time competitor price monitoring for dynamic pricing strategies." },
    { name: "Marketing Analytics", description: "Connect with marketing tools to optimize promotional strategies." },
  ];

  const caseStudies = [
    {
      icon: <FaChartLine className="text-4xl text-blue-400" />,
      title: "Dynamic Pricing Optimization",
      details: [
        { label: "Challenge", text: "Fluctuating market demand and competitor pricing" },
        { label: "Solution", text: "AI-driven real-time price adjustments across 10,000+ SKUs" },
        { label: "Outcome", text: "15% increase in profit margins within 3 months" },
      ],
    },
    {
      icon: <FaUsers className="text-4xl text-green-400" />,
      title: "Personalized Customer Segmentation",
      details: [
        { label: "Challenge", text: "Generic marketing campaigns with low conversion rates" },
        { label: "Solution", text: "AI-powered customer segmentation and tailored promotions" },
        { label: "Outcome", text: "30% improvement in campaign ROI and customer engagement" },
      ],
    },
    {
      icon: <FaPercentage className="text-4xl text-purple-400" />,
      title: "Intelligent Promotion Planning",
      details: [
        { label: "Challenge", text: "Ineffective promotional strategies leading to inventory imbalances" },
        { label: "Solution", text: "Data-driven promotion planning based on historical performance and market trends" },
        { label: "Outcome", text: "25% increase in promotional effectiveness and inventory turnover" },
      ],
    },
    {
      icon: <FaDollarSign className="text-4xl text-yellow-400" />,
      title: "Revenue Forecasting",
      details: [
        { label: "Challenge", text: "Inaccurate sales projections affecting business planning" },
        { label: "Solution", text: "Advanced ML models for precise revenue forecasting" },
        { label: "Outcome", text: "Improved accuracy of sales predictions by 40%" },
      ],
    },
    {
      icon: <FaBoxes className="text-4xl text-red-400" />,
      title: "Supply Chain Optimization",
      details: [
        { label: "Challenge", text: "Frequent stockouts and overstocking issues" },
        { label: "Solution", text: "AI-powered inventory forecasting and automated reordering" },
        { label: "Outcome", text: "50% reduction in stockouts and 30% decrease in carrying costs" },
      ],
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCase((prev) => (prev + 1) % caseStudies.length);
    }, 8000);
    return () => clearInterval(interval);
  }, [caseStudies.length]);

  return (
    <section className="bg-gray-900 py-20 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black"></div>
      <div className="container mx-auto px-4 relative z-10">
        <motion.h2 
          className="text-5xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
            JaanchAI:
          </span>
          <span className="text-white"> Revolutionizing E-commerce with AI-Driven Insights</span>
        </motion.h2>

        <motion.div 
          className="mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <h3 className="text-3xl font-bold text-blue-400 mb-4">Overview</h3>
          <p className="text-xl text-gray-300">
            <strong>JaanchAI</strong> is a cutting-edge AI-powered platform designed to revolutionize e-commerce and logistics operations. By automating complex business decisions and providing real-time, actionable insights, JaanchAI optimizes pricing strategies, inventory management, and promotional campaigns to maximize efficiency and profitability.
          </p>
        </motion.div>

        <motion.div 
          className="mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <h3 className="text-3xl font-bold text-blue-400 mb-6">Seamless Integrations</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {connectedSystems.map((system, index) => (
              <motion.div 
                key={index}
                className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm p-6 rounded-xl"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <FaLink className="text-2xl text-blue-400 mb-2" />
                <h4 className="text-xl font-semibold text-white mb-2">{system.name}</h4>
                <p className="text-gray-300">{system.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <motion.div 
          className="mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <h3 className="text-3xl font-bold text-blue-400 mb-6">Real-World Impact: JaanchAI in Action</h3>
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500 to-purple-600 rounded-2xl opacity-20 filter blur-3xl animate-pulse"></div>
            <div className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-xl p-8 rounded-2xl relative z-10">
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeCase}
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="flex items-center mb-6">
                    {caseStudies[activeCase].icon}
                    <h4 className="text-2xl font-bold text-white ml-4">{caseStudies[activeCase].title}</h4>
                  </div>
                  <ul className="space-y-4">
                    {caseStudies[activeCase].details.map((detail, index) => (
                      <li key={index} className="flex items-start">
                        <span className="text-blue-400 font-semibold mr-2">{detail.label}:</span>
                        <span className="text-gray-300">{detail.text}</span>
                      </li>
                    ))}
                  </ul>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
        >
          <h3 className="text-3xl font-bold text-blue-400 mb-4">Transformative Impact</h3>
          <p className="text-xl text-gray-300 mb-8">
            By harnessing the power of JaanchAI, businesses can automate complex decision-making processes, react swiftly to market changes, and gain a competitive edge. Our AI-driven platform empowers companies to optimize their operations, enhance customer experiences, and drive substantial growth in the dynamic world of e-commerce and logistics.
          </p>
          <motion.a
            href="request-demo"
            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-purple-700 transition-all duration-300 inline-flex items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Experience the JaanchAI Advantage
            <FaArrowRight className="ml-2" />
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default JaanchAIOverview;