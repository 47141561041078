import React, { useState } from 'react';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { FaTrash, FaChartLine, FaBoxes, FaSmile, FaDollarSign } from 'react-icons/fa';
import { useDrop } from 'react-dnd';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { motion, AnimatePresence } from 'framer-motion';

Chart.register(CategoryScale);

const KPIContainer = () => {
  const [addedKPIs, setAddedKPIs] = useState([]);

  const [{ isOver }, drop] = useDrop({
    accept: 'KPI',
    drop: (item) => addKPI(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const addKPI = (kpi) => {
    if (!addedKPIs.some((existingKpi) => existingKpi.id === kpi.id)) {
      setAddedKPIs((prevKpis) => [...prevKpis, kpi]);
    }
  };

  const removeKPI = (id) => {
    setAddedKPIs((prevKpis) => prevKpis.filter((kpi) => kpi.id !== id));
  };

  const chartData = {
    Sales: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      datasets: [{
        label: 'Sales',
        data: [12000, 15000, 17000, 20000, 25000, 30000],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      }],
    },
    Inventory: {
      labels: ['Product A', 'Product B', 'Product C', 'Product D', 'Product E'],
      datasets: [{
        label: 'Stock Level',
        data: [50, 75, 150, 125, 200],
        backgroundColor: ['#4CAF50', '#2196F3', '#FFC107', '#9C27B0', '#F44336'],
      }],
    },
    'Customer Satisfaction': {
      labels: ['Very Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very Dissatisfied'],
      datasets: [{
        label: 'Customer Satisfaction',
        data: [60, 25, 10, 3, 2],
        backgroundColor: ['#4CAF50', '#8BC34A', '#FFC107', '#FF9800', '#F44336'],
      }],
    },
    Revenue: {
      labels: ['Q1', 'Q2', 'Q3', 'Q4'],
      datasets: [{
        label: 'Revenue',
        data: [50000, 60000, 70000, 80000],
        backgroundColor: ['#3F51B5', '#2196F3', '#03A9F4', '#00BCD4'],
      }],
    },
  };

  const getChartComponent = (name) => {
    switch (name) {
      case 'Sales': return Line;
      case 'Inventory': return Bar;
      case 'Customer Satisfaction': return Doughnut;
      case 'Revenue': return Bar;
      default: return Line;
    }
  };

  const getIcon = (name) => {
    switch (name) {
      case 'Sales': return <FaChartLine />;
      case 'Inventory': return <FaBoxes />;
      case 'Customer Satisfaction': return <FaSmile />;
      case 'Revenue': return <FaDollarSign />;
      default: return <FaChartLine />;
    }
  };

  return (
    <motion.div
      ref={drop}
      className={`p-8 border-2 border-dashed ${isOver ? 'border-blue-500' : 'border-gray-600'} rounded-lg grid grid-cols-1 md:grid-cols-2 gap-8 w-full h-full bg-gray-900`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {addedKPIs.length === 0 ? (
        <motion.p 
          className="text-center w-full col-span-2 text-gray-400 text-xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          Drop KPIs here to analyze
        </motion.p>
      ) : (
        <AnimatePresence>
          {addedKPIs.map((kpi) => (
            <motion.div 
              key={kpi.id} 
              className="bg-gray-800 p-6 rounded-lg shadow-lg relative" 
              style={{ height: '350px' }}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
            >
              <motion.button
                className="absolute top-2 right-2 text-red-500 hover:text-red-700 bg-gray-700 p-2 rounded-full"
                onClick={() => removeKPI(kpi.id)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaTrash />
              </motion.button>
              <h3 className="text-xl font-semibold mb-4 text-white flex items-center">
                {getIcon(kpi.name)}
                <span className="ml-2">{kpi.name}</span>
              </h3>
              <div style={{ height: '250px' }}>
                {React.createElement(getChartComponent(kpi.name), {
                  data: chartData[kpi.name],
                  options: { 
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        labels: {
                          color: 'white'
                        }
                      }
                    },
                    scales: {
                      x: {
                        ticks: { color: 'white' }
                      },
                      y: {
                        ticks: { color: 'white' }
                      }
                    }
                  }
                })}
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      )}
    </motion.div>
  );
};

export default KPIContainer;