import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChartLine, FaChartPie, FaRobot, FaArrowRight, FaBrain, FaBolt } from 'react-icons/fa';

const features = [
  {
    title: "Sales Insight",
    description: "Optimize your sales strategy with AI-driven insights. Track performance, predict trends, and maximize revenue.",
    gradient: "from-blue-400 to-indigo-600",
    icon: FaChartLine,
    color: "text-blue-400",
  },
  {
    title: "Margin Management",
    description: "Identify key profitability drivers and focus on high-margin products. Refine pricing strategies with deep cost insights.",
    gradient: "from-green-400 to-teal-600",
    icon: FaChartPie,
    color: "text-green-400",
  },
  {
    title: "AI CoPilot Querying",
    description: "Harness 10X faster data analysis with natural language processing. Get instant answers to complex business questions.",
    gradient: "from-purple-400 to-pink-600",
    icon: FaRobot,
    color: "text-purple-400",
  },
];

const JaanchCopilot = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const currentFeature = features[currentIndex];

  return (
    <section className="bg-gray-900 text-white min-h-screen flex items-center justify-center relative overflow-hidden py-20">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black"></div>
      <div className="container mx-auto px-4 relative z-10">
        <motion.h2 
          className="text-5xl sm:text-6xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
            JaanchCopilot
          </span>
        </motion.h2>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="relative h-96">
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500 to-purple-600 rounded-2xl opacity-20 filter blur-3xl animate-pulse"></div>
            <div className="relative h-full flex items-center justify-center">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentIndex}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.5 }}
                  className="text-center"
                >
                  <currentFeature.icon className={`text-8xl ${currentFeature.color} mb-6`} />
                  <h3 className="text-3xl font-bold mb-4">{currentFeature.title}</h3>
                  <p className="text-xl text-gray-300 max-w-md mx-auto">{currentFeature.description}</p>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
          
          <div>
            <div className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-xl p-8 rounded-2xl shadow-2xl">
              <h3 className="text-2xl font-bold mb-6">Why Choose JaanchCopilot?</h3>
              <ul className="space-y-4">
                <motion.li 
                  className="flex items-center"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <FaBrain className="text-blue-400 mr-3 text-xl" />
                  <span>Advanced AI algorithms for precise insights</span>
                </motion.li>
                <motion.li 
                  className="flex items-center"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  <FaBolt className="text-yellow-400 mr-3 text-xl" />
                  <span>Boost efficiency and revenue by up to 30%</span>
                </motion.li>
                <motion.li 
                  className="flex items-center"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <FaRobot className="text-purple-400 mr-3 text-xl" />
                  <span>Seamless integration with existing systems</span>
                </motion.li>
              </ul>
            </div>
            <motion.div 
              className="mt-8 text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <a
                href="request-demo"
                className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-purple-700 transition-all duration-300 inline-flex items-center"
              >
                Get Started with JaanchCopilot
                <FaArrowRight className="ml-2" />
              </a>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JaanchCopilot;