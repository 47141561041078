import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaCalendarAlt, FaRocket } from 'react-icons/fa';

const CalendlyScheduler = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="bg-gray-900 py-20 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-600 to-purple-800 opacity-20"></div>
      <div className="container mx-auto px-4 max-w-4xl relative z-10">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl font-bold mb-4 text-white">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">
              Talk to an Expert
            </span>
          </h2>
          <p className="text-xl mb-8 text-gray-300">
            Schedule a call with one of our experts to discuss how we can help your business reach new heights.
          </p>
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="bg-gray-800 shadow-2xl rounded-lg p-8"
        >
          <div className="flex items-center justify-center mb-6">
            <FaCalendarAlt className="text-4xl text-blue-400 mr-4" />
            <h3 className="text-2xl font-semibold text-white">Book Your Session</h3>
          </div>
          <div 
            className="calendly-inline-widget" 
            data-url="https://calendly.com/gopal-jaanch/30min" 
            style={{ minWidth: '320px', height: '630px' }} 
          />
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="mt-12 text-center"
        >
          <p className="text-lg text-gray-300 mb-6">
            Ready to revolutionize your business with AI-driven insights?
          </p>
          <a 
            href="request-demo" 
            className="inline-flex items-center bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-purple-700 transition-all duration-300"
          >
            Get Started Now <FaRocket className="ml-2" />
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default CalendlyScheduler;