import React from 'react';
import { motion } from 'framer-motion';
import { FaPlay, FaPlug, FaChartLine, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const GettingStarted = () => {
  const steps = [
    {
      icon: <FaPlay className="text-5xl text-blue-400" />,
      title: "Request a Demo",
      description: "See JaanchAI in action and understand how it can benefit your business.",
      gradient: "from-blue-400 to-indigo-600",
    },
    {
      icon: <FaPlug className="text-5xl text-green-400" />,
      title: "Connect Your Data",
      description: "Seamlessly integrate your data with JaanchAI for comprehensive analysis.",
      gradient: "from-green-400 to-teal-600",
    },
    {
      icon: <FaChartLine className="text-5xl text-purple-400" />,
      title: "Review Insights",
      description: "Explore AI-driven insights and optimize your business strategies.",
      gradient: "from-purple-400 to-pink-600",
    },
  ];

  return (
    <section className="bg-gray-900 py-20 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black"></div>
      <div className="container mx-auto px-4 relative z-10">
        <motion.h2 
          className="text-5xl font-bold mb-16 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
            Getting Started
          </span>
          <span className="text-white"> is Easy!</span>
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {steps.map((step, index) => (
            <motion.div 
              key={index}
              className={`bg-gradient-to-br ${step.gradient} p-1 rounded-2xl shadow-lg`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
            >
              <div className="bg-gray-800 p-8 rounded-2xl h-full flex flex-col justify-between">
                <div>
                  <div className="flex justify-center mb-6">
                    {step.icon}
                  </div>
                  <h3 className="text-2xl font-semibold mb-4 text-white">{step.title}</h3>
                  <p className="text-gray-300">{step.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        <motion.div 
          className="text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <Link 
            to="/talk-to-expert" 
            className="inline-flex items-center bg-gradient-to-r from-blue-500 to-purple-600 text-white py-4 px-10 rounded-full shadow-lg hover:from-blue-600 hover:to-purple-700 transition-all duration-300 text-xl font-bold"
          >
            Request a Demo
            <FaArrowRight className="ml-3 text-2xl" />
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

export default GettingStarted;